<template>
  <div>
    <v-btn
      bottom
      class="secondary"
      dark
      fab
      fixed
      right
      @click="dialog = !dialog"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" persistent width="900px">
      <v-card>
        <v-form ref="form" v-model="valid">
          <v-card-title class="primary darken-2 white--text justify-center">
            Invite Vendor
          </v-card-title>
          <v-container>
            <v-row class="mx-2">
              <v-col class="align-center justify-space-between" cols="12">
                <v-row align="center" class="mr-0">
                  <v-col cols="6">
                    <v-text-field
                      prepend-icon="mdi-account"
                      label="Company Name"
                      v-model="companyName"
                      :rules="[required('Company Name')]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      prepend-icon="mdi-email"
                      label="company Email"
                      v-model="companyEmail"
                      :rules="[required('Email'), emailFormat()]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  prepend-icon="mdi-phone"
                  label="Phone Number"
                  v-model="phoneNumber"
                  :rules="[required('Phone Number'), numberFormat()]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  prepend-icon="mdi-folder"
                  label="KRA PIN"
                  v-model="kra"
                  :rules="[required('KRA Pin')]"
                >
                </v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  prepend-icon="mdi-folder-account"
                  label="Bank Name"
                  v-model="bankName"
                  :rules="[required('Bank Name')]"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  type="tel"
                  prepend-icon="mdi-folder-pound"
                  label="Bank Branch"
                  v-model="bankBranch"
                  :rules="[required('Bank Branch')]"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  type="tel"
                  prepend-icon="mdi-folder-table"
                  label="Bank Account No."
                  v-model="bankAccount"
                  :rules="[required('Bank account'), numberFormat()]"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  type="tel"
                  prepend-icon="mdi-badge-account-horizontal"
                  label="Physical Address"
                  v-model="address"
                  :rules="[required('Physical Address')]"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-select
                  prepend-icon="mdi-account-hard-hat"
                  item-text="name"
                  item-value="value"
                  :items="roles"
                  label="Service"
                  v-model="assignedService"
                  :rules="[required('Service')]"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <div class="ma-auto pb-5">
              <v-btn
                rounded
                class="ma-5 pa-4"
                color="secondary"
                @click="inviteVendor"
                :loading="loading"
                :disabled="!valid"
                :dialogInvite="dialogInvite"
              >
                Invite Vendor
                <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
              </v-btn>

              <v-btn rounded class="ma-5 pa-4" color="" @click="clear" dark>
                Clear
                <v-icon dark right class="ml-2"> mdi-format-clear </v-icon>
              </v-btn>

              <v-btn
                rounded
                class="ma-5 pa-4"
                color="red"
                @click="vendorCancel"
                dark
              >
                Cancel
                <v-icon dark right> mdi-close </v-icon>
              </v-btn>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import validation from '@/utils/Validations'
export default {
  data: () => ({
    valid: false,
    companyName: '',
    companyEmail: '',
    phoneNumber: 254 + '',
    kra: '',
    bankName: '',
    bankBranch: '',
    bankAccount: '',
    vat: '',
    address: '',
    assignedService: '',
    roles: [
      { name: 'Towing', value: 'towTruck' },
      { name: 'Car Rental', value: 'carRental' },
      { name: 'Garage', value: 'garage' },
      { name: 'External Assessor', value: 'externalAssessor' },
      { name: 'Ambulance', value: 'ambulance' },
      { name: 'Wind Screen Fitters', value: 'windscreenFitter' },
    ],
    dialog: false,
    dialogInvite: false,
    alert: false,
    ...validation,
  }),

  computed: {
    ...mapState(['loading']),
  },

  methods: {
    inviteVendor() {
      this.dialogInvite = true
      this.$store.state.loading = true

      const inviteData = {
        name: this.companyName,
        email: this.companyEmail,
        phone_number: this.phoneNumber,
        kra_pin: this.kra,
        physical_address: this.address,
        bank_name: this.bankName,
        bank_account: this.bankAccount,
        bank_branch: this.bankBranch,
        role: this.assignedService,
      }
      this.$store.dispatch('inviteVendor', inviteData)
      this.$refs.form.reset()
    },

    vendorCancel() {
      this.dialog = false
      this.$refs.form.reset()
    },

    clear() {
      this.$refs.form.reset()
    },
  },
}
</script>
