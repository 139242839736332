export const required = (propertyType) => {
  return (v) => (v && v.length > 0) || `${propertyType} field is required`
}

let minLength = (propertyType, min_length) => {
  return (v) =>
    (v && v.length >= min_length) ||
    `${propertyType} must not be less than ${min_length} characters`
}

const confirmPassword = (propertyType, password) => (v) =>
  (v && v === password) || `${propertyType} must be same as entered password`

let maxLength = (propertyType, max_length) => {
  return (v) =>
    (v && v.length <= max_length) ||
    `${propertyType} must be less than ${max_length}`
}

const max = (propertyType, maximum) => (v) =>
  (v && v <= maximum) || `${propertyType} must not be more than ${maximum}`

const min = (propertyType, minimum) => (v) =>
  (v && v >= minimum) || `${propertyType} must be ${minimum} or more`

  const greaterThan = (propertyType, minimum) => (v) => {
    const numV = parseFloat(v);
    const numMinimum = parseFloat(minimum);
    return (numV !== undefined && numV > numMinimum) || `${propertyType} must be greater than ${minimum}`;
  };
  

let emailFormat = () => {
  let regex = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/

  return (v) => (v && regex.test(v)) || 'Must be a valid email'
}

const mobileFormat = () => {
  const regex = /^\+254\d{9}/
  return (v) => (v && regex.test(v)) || 'Must start with +254...'
}

let numberFormat = () => {
  let regex = /^\d+$/

  return (v) => (v && regex.test(v)) || 'Must be a valid number'
}

const lowerCaseFormat = () => {
  const regex = /^(.*[a-z].*)$/
  return (v) => (v && regex.test(v)) || 'Must contain a lowercase character'
}

const upperCaseFormat = () => {
  const regex = /^(.*[A-Z].*)$/
  return (v) =>
    (v && regex.test(v)) || 'Must have at least 1 uppercase character'
}

const digitFormat = () => {
  const regex = /^(.*\d.*)$/
  return (v) => (v && regex.test(v)) || 'Must have at least 1 digit'
}

const specialCharFormat = () => {
  const regex = /^(.*[!@#$%^&*)(+=.,_-].*)$/
  return (v) => (v && regex.test(v)) || 'Must have at least 1 special character'
}

const fullNameFormat = () => {
  const regex = /^\s*([A-Za-z]+([.,] |[-']| ))+[A-Za-z]+\.?\s*$/
  return (v) => (v && regex.test(v)) || 'Must be valid name'
}

const check = () => (v) => v === true || 'You must agree to continue!'

export default {
  required,
  minLength,
  maxLength,
  greaterThan,
  max,
  min,
  emailFormat,
  mobileFormat,
  numberFormat,
  confirmPassword,
  lowerCaseFormat,
  upperCaseFormat,
  digitFormat,
  specialCharFormat,
  fullNameFormat,
  check,
}
